<template>
    <div class="mainform">
        <!-- 供应商对账单列表查看详情 -->
        <div class="mainHeader">对账单  <span @click="back" class="back">返回></span></div>
        <div class="form">
          <div class="header">对账内容
              <span class="showOff">
                <el-button @click="openContent" size="small" type="text">{{isShowContent?"展开":"收起"}}
                  <i :class="[{'el-icon-arrow-down':isShowContent},{'el-icon-arrow-up':!isShowContent}]"></i>
                </el-button>
              </span>
            </div>
              <el-form  class="content bascform disabled" v-if="isShowContent"  :model="form" :disabled="true" label-width="120px">
                  <div  class="col col4">
                    <el-form-item label="对账单编号" >
                        <el-input v-model="form.number"></el-input>
                    </el-form-item>
                  </div>
                  <div  class="col col4">
                    <el-form-item label="供应商" >
                      <span class="onlyText">
                        {{form.supplierCode+'-'+form.supplier}}
                      </span>
                    </el-form-item>
                  </div>
                  <div  class="col col4">
                    <el-form-item label="公司名称" >
                      <span class="onlyText">
                        {{form.corporateCode+'-'+form.corporateName}}
                      </span>
                    </el-form-item>
                  </div>
                  <div  class="col col4">
                    <el-form-item label="开票方">
                      <span class="onlyText">
                        {{form.billingPartyCode+'-'+form.billingParty}}
                      </span>
                    </el-form-item>
                  </div>
                  <div  class="col col4">
                    <el-form-item label="币种" >
                        <el-input v-model="form.currency"></el-input>
                    </el-form-item>
                  </div>
                    <div class="col col4">
                    <el-form-item label="对账单类型">
                      <el-select v-model="form.statementType" >
                        <el-option label="正常采购" :value="0"></el-option>
                        <el-option label="分期付款" :value="1"></el-option>
                        </el-select>
                    </el-form-item>
                  </div>
                  <div  class="col col4">
                    <el-form-item label="含税总额" >
                        <el-input v-model="form.invoiceAmountIncludingTax"></el-input>
                    </el-form-item>
                  </div>
                  <div class="col col4">
                    <el-form-item label="是否寄售">
                      <el-select v-model="form.consignmentOrNot" >
                          <el-option label="是" :value="0"></el-option>
                          <el-option label="否" :value="1"></el-option>
                        </el-select>
                    </el-form-item>
                  </div>
                  <div  class="col col4">
                    <el-form-item label="未税总额" >
                        <el-input v-model="form.noTaxInvoiceAmount"></el-input>
                    </el-form-item>
                  </div>
                  <div class="col col4">
                    <el-form-item label="对账单状态">
                      <el-select v-model="form.status" >
                        <el-option label="待确认" :value="0"></el-option>
                        <el-option label="已完成" :value="1"></el-option>
                        <el-option label="审批中" :value="2"></el-option>
                        <el-option label="驳回" :value="3"></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div  class="col col4">
                    <el-form-item label="总税额" >
                      <el-input v-model="form.invoiceAmount"></el-input>
                    </el-form-item>
                  </div>
                  <div class="col col4">
                    <el-form-item label="网上发票状态">
                      <el-select v-model="form.createOnlineInvoice" >
                        <el-option label="完全创建" :value="0"></el-option>
                        <el-option label="未创建" :value="1"></el-option>
                        <el-option label="部分创建" :value="2"></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div class="col col4" v-if="xzcyzeshow">
                    <el-form-item label="修正差异总额">
                      <span class="onlyText">
                        {{xzcyze}}
                      </span>
                    </el-form-item>
                  </div>
                  <div class="col col4">
                    <el-form-item label="备注">
                      <el-input  v-model="form.remarks"></el-input>
                    </el-form-item>
                  </div>
                  <div  class="col col8">
                    <el-form-item label="差异原因" >
                      <span class="onlyText">
                        {{form.differenceReason}}
                      </span>
                    </el-form-item>
                  </div>
              </el-form>
            <div class="header">对账明细
              <span class="showOff">
                <el-button @click="open" size="small" type="text">{{isclose?"展开":"收起"}}
                  <i :class="[{'el-icon-arrow-down':isclose},{'el-icon-arrow-up':!isclose}]"></i>
                </el-button>
              </span>
            </div>
                <global-table v-if="isclose" class="bascform" @dshow="diaShow" max-height="350" :tableField="tableField" :tableData="tableDataDetail" ></global-table>
            <div class="header">物料汇总
              <span class="showOff">
                <el-button @click="show" size="small" type="text">{{isShow?"展开":"收起"}}
                  <i :class="[{'el-icon-arrow-down':isShow},{'el-icon-arrow-up':!isShow}]"></i>
                </el-button>
              </span>
            </div>
                <global-table v-if="isShow" class="bascform"  max-height="350" :tableField="MaterialData" :tableData="MaterialtableData" ></global-table>
                <approval-mind></approval-mind>
            <div class="action" v-if="form.status == 0">
                <el-button class="submit" type="primary" size="medium" @click="feedbackPriceBtn">确认</el-button>
                <!-- <el-button class="submit" type="primary" size="medium" @click="confirmBtn" >确认</el-button> -->
            </div>
            <div class="action" v-if="form.status == 3">
                <el-button class="submit" type="primary" size="medium" @click="feedbackPriceBtn">确认</el-button>
            </div>
        </div>
        <dialog-box  ref="addDialog" dialogWidth="85%" :formData="form" :dialogShow="dialogShow" @handleClose='handleClose'  title="确认对账单金额"
        componentName="SupStatementDetailDialogN" @problemFeedback="problemFeedback"
                :buttonData="[{ label: '关闭', action: 'handleClose', size: 'mini' },{ label: '确认', action: 'problemFeedback', size: 'mini', type:'primary'}]">
        </dialog-box>
        <dialog-box  ref="addDialog" dialogWidth="50%" :showData="showData" :dialogShow="mVNdialogShow" @handleClose='handleClose'  title="物料凭证"
        componentName="materialVoucherNoDialog" :buttonData="[{ label: '关闭', action: 'handleClose', size: 'mini' }]">
        </dialog-box>
    </div>
</template>

<script>

import Component from '@/assets/js/components.js'
import { tableField, MaterialData } from './js/supplierStatementDetail'
import { request, requestForm } from '@/assets/js/http.js'
import { FlowCtlData } from '@/assets/js/FlowManage.js'
export default {
  components: Component.components,
  name: 'SupplierStatementDetail',
  data () {
    FlowCtlData.docid = ''
    return {
      tableField: [...tableField],
      MaterialData: MaterialData,
      tableDataDetail: [], // 对账明细
      MaterialtableData: [], // 物料汇总
      dialogShow: false,
      mVNdialogShow: false,
      isclose: true,
      isShow: true,
      isShowContent: true,
      form: {},
      tableData: [],
      showData: {},
      submitData: {},
      xzcyze: 0,
      xzcyzeshow: false
    }
  },
  mounted () {
    this.SupplierStatementDetail()
  },
  methods: {
    SupplierStatementDetail () {
      var id = this.$route.query.id
      requestForm('/api/reconciliation/reconciliationInfo/queryOne?id=' + id, 'post', '').then((res) => {
        if (res.code === '200') {
          this.form = res.data
          this.tableDataDetail = res.data.reconciliations // 对账明细
          this.MaterialtableData = res.data.materialList // 物料汇总
          this.tableShowitem()
        }
        FlowCtlData.flowid = 'CheckDifferences'
        FlowCtlData.docid = this.$route.query.id
        FlowCtlData.initialize()
        request(`/api/supplier/basicData/getByErpCode/${this.form.billingPartyCode}`, 'get').then((response) => {
          if (response.code === '200') {
            if (response.data === null || response.data === 'null') {
              this.$message({
                message: '未找到采购员！',
                type: 'error'
              })
              return false
            }
            this.submitData.buyer = response.data.buyerCode.replace(/,/g, ';')
          }
        })
      })
      FlowCtlData.getApprPsn('551625315191681024').then((val) => { this.submitData.buyerLeader = val })
    },
    tableShowitem () {
      // 对账单状态在未确认状态下修正差异不显示
      if (this.form.status === 0) {
        for (const index in this.tableField) {
          if (this.tableField[index].id === 'xzcy') {
            this.tableField.splice(index, 1)
          }
        }
        for (const index in this.MaterialData) {
          if (this.MaterialData[index].id === 'xzcy') {
            this.MaterialData.splice(index, 1)
          }
        }
        this.xzcyzeshow = false
      } else {
        for (const item of this.form.reconciliations) {
          this.xzcyze = parseFloat(this.xzcyze) + (parseFloat(item.revisedAmountIncludingTax) - parseFloat(item.amountIncludingTax))
        }
        this.xzcyze = this.xzcyze.toFixed(2)
        this.xzcyzeshow = true
      }
    },
    open () {
      this.isclose = !this.isclose
    },
    openContent () {
      this.isShowContent = !this.isShowContent
    },
    show () {
      this.isShow = !this.isShow
    },
    // 反馈价格差异弹窗确认按钮
    problemFeedback () {
      console.log(this.$refs.addDialog.$refs.content.differenceTotal)
      const dfnum = parseFloat(this.$refs.addDialog.$refs.content.differenceTotal)
      this.submitData.dzdId = this.$route.query.id
      this.submitData.infos = []
      this.submitData.differenceReason = this.form.differenceReason
      if (dfnum >= 100 || dfnum <= -100) {
        if ((this.form.differenceReason ?? '') === '') {
          this.$message({
            message: '差异总额>=100或<=-100时必须填写差异原因！',
            type: 'error'
          })
          return false
        }
      }
      let mustAppr = false
      for (const item of this.form.reconciliations) {
        this.submitData.infos.push({ id: item.id, revisedAmountIncludingTax: item.revisedAmountIncludingTax })
        if (parseInt(item.reconciledQuantity) >= 0) {
          if (parseInt(item.reconciledQuantity) < 1000) {
            if (parseFloat(item.revisedAmountIncludingTax) - parseFloat(item.amountIncludingTax) > 10) {
              mustAppr = true
            }
          } else if (parseInt(item.reconciledQuantity) >= 1000 && parseInt(item.reconciledQuantity) < 5000) {
            if (parseFloat(item.revisedAmountIncludingTax) - parseFloat(item.amountIncludingTax) > 30) {
              mustAppr = true
            }
          } else if (parseInt(item.reconciledQuantity) >= 5000 && parseInt(item.reconciledQuantity) < 10000) {
            if (parseFloat(item.revisedAmountIncludingTax) - parseFloat(item.amountIncludingTax) > 50) {
              mustAppr = true
            }
          } else if (parseInt(item.reconciledQuantity) >= 10000) {
            if (parseFloat(item.revisedAmountIncludingTax) - parseFloat(item.amountIncludingTax) > 100) {
              mustAppr = true
            }
          }
        } else {
          if (parseInt(item.reconciledQuantity) > -1000) {
            if (parseFloat(item.revisedAmountIncludingTax) - parseFloat(item.amountIncludingTax) > 10) {
              mustAppr = true
            }
          } else if (parseInt(item.reconciledQuantity) <= -1000 && parseInt(item.reconciledQuantity) > -5000) {
            if (parseFloat(item.revisedAmountIncludingTax) - parseFloat(item.amountIncludingTax) > 30) {
              mustAppr = true
            }
          } else if (parseInt(item.reconciledQuantity) <= -5000 && parseInt(item.reconciledQuantity) > -10000) {
            if (parseFloat(item.revisedAmountIncludingTax) - parseFloat(item.amountIncludingTax) > 50) {
              mustAppr = true
            }
          } else if (parseInt(item.reconciledQuantity) <= -10000) {
            if (parseFloat(item.revisedAmountIncludingTax) - parseFloat(item.amountIncludingTax) > 100) {
              mustAppr = true
            }
          }
        }
      }
      if (mustAppr) {
        this.flowAction()
      } else {
        let sum = 0
        for (const ietm of this.submitData.infos) {
          sum = sum + parseFloat(ietm.revisedAmountIncludingTax)
        }
        sum = sum.toFixed(2)
        this.$confirm(`本次修正含税金额为：${sum}。是否反馈？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.submitData.approvalStatus = 1
          this.submitData.approval = ''
          this.submitData.approvalComplete = localStorage.getItem('userName')
          request('/api/reconciliation/reconciliationInfo/feedbackDifferences', 'post', this.submitData).then((res) => {
            if (res.code === '200') {
              this.$message({
                message: '提交成功！',
                type: 'success'
              })
              this.$router.push('/FinancialManagement/SupplierStatement')
            }
          })
        }).catch(() => {
          FlowCtlData.formAlert(this, 'info')
        })
      }
    },
    flowAction () {
      // this.submitData.buyer = 'xuejh'
      let sum = 0
      for (const ietm of this.submitData.infos) {
        sum = sum + parseFloat(ietm.revisedAmountIncludingTax)
      }
      sum = sum.toFixed(2)
      FlowCtlData.getNodeAction('TJ', this.submitData).then((val) => {
        if (val) {
          FlowCtlData.alertDialog(this, `本次修正含税金额为：${sum};`).then(() => {
            this.submitData.approvalComplete = this.form.approvalComplete
            FlowCtlData.setFormData(FlowCtlData, this.submitData)
            request('/api/reconciliation/reconciliationInfo/feedbackDifferences', 'POST', this.submitData).then((Response) => {
              if (Response.code === '200') {
                FlowCtlData.setNodeAction().then((val) => {
                  FlowCtlData.formAlert(this, 'success')
                  this.$router.push('/FinancialManagement/SupplierStatement')
                })
              }
            })
          }).catch(() => { FlowCtlData.formAlert(this, 'info') })
        }
      }).catch(() => { FlowCtlData.formAlert(this, 'error') })
    },
    diaShow (scope) {
      var number = scope.row.materialVoucherNo
      requestForm('/api/reconciliation/materialDocument/queryVoucherNo?number=' + number, 'post', '').then((res) => {
        if (res.code === '200') {
          this.showData = res.data
          this.mVNdialogShow = true
        }
      })
    },
    feedbackPriceBtn () {
      this.dialogShow = true
    },
    confirmBtn () { // 更新对账单状态
      const obj = {
        id: this.$route.query.id,
        status: 1 // 状态
      }
      requestForm('/api/reconciliation/reconciliationInfo/updateStatus', 'post', obj).then((res) => {
        if (res.code === '200') {
          this.$message({
            message: '修改成功',
            type: 'success'
          })
          this.$router.push({
            path: '/FinancialManagement/SupplierStatement' // 返回对账单列表
          })
        }
      })
    },
    handleClose () {
      this.dialogShow = false
      this.mVNdialogShow = false
    },
    back: () => {
      window.history.back()
    }
  }
}
</script>
<style scoped lang="scss">
  @import '@/assets/css/form.scss';
  @import '@/assets/css/elForm.scss';
  .bottom{
    margin-top: 30px;
  }
   .showOff{
   float: right;
 }
</style>
